@media (max-width: 540px) {
  .tv-logo {
    width: 20%;
    height: auto;
    padding-top: 10px;
  }
}
@media (max-width: 350px) {
  .tv-logo {
    width: 15%;
    height: auto;
    padding-top: 20px;
  }
}
td {
  padding: 5px !important;
}
.fit-content {
  height: fit-content;
  width: fit-content;
}
input.inline-checkbox {
  opacity: 1 !important;
  position: relative !important;
  pointer-events: all !important;
}

body {
  background-image: url('onescreen/images/background.jpg');
  background-repeat: repeat;
  background-attachment: fixed;
}

div.main-body {
  margin-top: 70px;
}

.filter-input {
  border: none;
}

.compact-col {
  margin-bottom: -60px;
}

.larger-text {
  font-size: x-large;
}
.no-uppercase {
  text-transform: none !important;
}
#root {
  font-family: 'Roboto', 'Alef', sans-serif;
}
.uppercase {
  text-transform: uppercase;
}

.terra-footer-grey {
  background-color: #444;
}
.terra-blue-text {
  color: #1e376d;
}
.terra-blue {
  background-color: #1e376d;
}
.terra-green-text {
  color: #4d9736;
}
.terra-green {
  background-color: #4d9736;
}
.solar-performance-bar {
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.solar-performance-bar:hover {
  box-shadow: black 0px 0px 2px 0px;
}

.data-url-anchor {
  display: none;
}
